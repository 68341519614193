<template lang="html">
	<div class="dcontent">
		<div class="dtitle flex">
			<el-button size="small" icon="el-icon-back" @click="handleNavToBack()">返回</el-button>
			<p class="name">更新日志</p>
		</div>
		<el-table class="table" :data="list" :cell-style="{color:'#333333',borderColor:'#F5F5F5',fontSize:'16px'}" :header-cell-style="{fontSize:'16px',background:'#F5F5F5',color:'#118786'}"
			height="calc(100% - 150px)">
			<el-table-column prop="version" label="版本"></el-table-column>
			<el-table-column prop="content" label="内容"></el-table-column>
			<el-table-column prop="create_time" label="日期"></el-table-column>
		</el-table>
		<div class="paging">
			<el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
			curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        getList() {
			this.http.post('/admin.sysLog/index', {
                curr: this.curr,
                row: this.row,
            }).then(res => {
                this.list = res.list;
                this.curr = res.curr;
                this.pages = res.pages;
                this.count = res.count;
            });
        },
        handleNavToBack() {
            this.$router.go(-1)
        },
		//翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>

<style lang="css" scoped>
.dcontent{
	padding: 20px;
	box-sizing: border-box;
	height: 100vh;
}
button{
	background: #118786;
	border-radius: 4px;
	border: 0;
	color: #fff;
	font-size: 16px;
}
.name{
	font-size: 24px;
	color: #333;
	margin-right: auto;
	margin-left: 25px;
}
.table{
	/* height: calc(100% - 50px); */
}
.dtitle{
	margin-bottom: 30px;
}
</style>
